<template>
  <div :ref="key" @click="goProfile(cat)">
    <img
      v-if="hasImage(cat.image)"
      :src="getImageUrl(cat.image, 'tr=fo-cat,ar-1_1,w-128,f-webp')"
      class="aspect-square w-14 flex-none"
      :class="rounded ? 'rounded-full' : ''"
    />
    <div
      v-else
      class="flex h-full w-full items-center justify-center text-gray-300 text-xl"
    >
      <font-awesome-icon :icon="['fal', 'camera-slash']" />
    </div>
    <div
      class="hidden relative"
      v-if="hasImage(cat.image)"
      :ref="`${key}-content`"
    >
      <img
        :src="getImageUrl(cat.image, 'tr=fo-cat,ar-1_1,w-400,f-webp')"
        class="aspect-square flex-none rounded"
      />
      <div
        class="p-6 pt-8 rounded text-white text-center absolute bg-gradient-to-t from-gray-800 bottom-0 w-full max-w-32 truncate text-lg"
      >
        {{ cat.name || cat.catName }}
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import tippy from 'tippy.js';
export default {
  name: 'CatImage',
  props: {
    cat: {
      type: Object,
      required: true
    },
    rounded: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tippyInstance: null,
      key: utils.createKey('cat-image', 6)
    };
  },
  methods: {
    getImageUrl(image, config) {
      return image + `?${config}`;
    },
    hasImage(image) {
      // check if images has imagekit in url
      return image.includes('ik.imagekit.io');
    },
    goProfile(cat) {
      if (this.link && cat.usersId) {
        this.$router.push({
          name: 'userProfile',
          params: { id: cat.usersId, tab: 'activity' }
        });
      }
    }
  },
  mounted() {
    // create a tippy instance
    this.$nextTick(() => {
      if (this.hasImage(this.cat.image)) {
        console.log(this.$refs[this.key], this.$refs[`${this.key}-content`]);
        this.tippyInstance = tippy(this.$refs[this.key], {
          allowHTML: true,
          content: this.$refs[`${this.key}-content`].innerHTML,
          placement: 'top',
          delay: [500, 0],
          theme: 'light',
          arrow: false
        });
      }
    });
  }
};
</script>

<style></style>
